import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import * as dayjs from 'dayjs';
import * as weekday from 'dayjs/plugin/weekday';
import * as localeData from 'dayjs/plugin/localeData';
import * as isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import * as updateLocale from 'dayjs/plugin/updateLocale';

export interface StandaloneDropdownDatepickerButtonItems {
  value: number;
  isToday: boolean;
  isSelected: boolean;
}

dayjs.extend(updateLocale);

dayjs.updateLocale('en', {
  monthsShort: [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec',
  ],
  weekdaysMin: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'],
});

dayjs.extend(weekday);
dayjs.extend(localeData);
dayjs.extend(isSameOrBefore);

@Component({
  selector: 'app-datepicker',
  templateUrl: './datepicker.component.html',
})
export class DatepickerComponent implements OnInit {
  @Output() selected = new EventEmitter<dayjs.Dayjs>();

  @Input()
  set appSelectedDayjs(selectedDayjs: dayjs.Dayjs | null) {
    if (selectedDayjs) {
      this.yearCurrent = this.yearSelected = selectedDayjs.get('year');
      this.monthCurrent = this.monthSelected = selectedDayjs.get('month');
      this.dayCurrent = this.daySelected = selectedDayjs.date();
      this.timeCurrent = this.timeSelected = selectedDayjs.get('hour');

      this.getYearList();
      this.getMonthList();
      this.getDayList();
      this.getTimeList();

      this.setDisablePreviousViewNavigation();
    } else {
      this.yearSelected = null;
      this.monthSelected = null;
      this.daySelected = null;
      this.timeSelected = null;
    }
  }

  @Input()
  set appDisablePrevious(disablePrevious: boolean) {
    this.disablePrevious = disablePrevious;
  }

  @Input()
  set appIsShowTimepicker(timepicker: boolean) {
    this.isShowTimepicker = timepicker;
  }

  @Input()
  set appEndpointMode(endpointMode: 'time' | 'day' | 'month' | 'year') {
    this.endpointMode = endpointMode;

    this.onViewChange(this.endpointMode, null);
  }

  calendarCurrent: dayjs.Dayjs = dayjs();
  calendarView: 'day' | 'month' | 'year' | 'time' = 'day';

  yearSelected!: number | null;
  yearToday!: number;
  yearCurrent!: number;
  yearList: StandaloneDropdownDatepickerButtonItems[] = [];

  monthSelected!: number | null;
  monthToday!: number;
  monthCurrent!: number;
  monthList: StandaloneDropdownDatepickerButtonItems[] = [];

  daySelected!: number | null;
  dayToday!: number;
  dayCurrent!: number;
  dayList: StandaloneDropdownDatepickerButtonItems[][] = [[], [], []];

  timeSelected!: number | null;
  timeToday!: number;
  timeCurrent!: number;
  timeList: StandaloneDropdownDatepickerButtonItems[] = [];

  labelDays: string[] = dayjs.weekdaysMin(true);
  labelMonths: string[] = dayjs.months();
  labelMonthsShort: string[] = dayjs.monthsShort();

  disablePrevious!: boolean;
  disablePreviousViewNavigation!: boolean;

  isShowTimepicker: boolean = false;

  endpointMode: 'time' | 'day' | 'month' | 'year' = 'day';

  constructor() {}

  ngOnInit(): void {
    this.yearCurrent = this.yearToday = this.calendarCurrent.get('year');
    this.monthCurrent = this.monthToday = this.calendarCurrent.get('month');
    this.dayCurrent = this.dayToday = this.calendarCurrent.date();
    this.timeCurrent = this.timeToday = this.calendarCurrent.get('hour');

    this.getYearList();
    this.getMonthList();
    this.getDayList();
    this.getTimeList();

    this.setDisablePreviousViewNavigation();
  }

  onViewNavigate(direction: boolean, event: Event): void {
    if (event?.stopPropagation) {
      event.stopPropagation();
    }

    let dayjsDate: dayjs.Dayjs = dayjs();

    switch (this.calendarView) {
      case 'time':
        if (direction) {
          dayjsDate = this.getDayjsDate().add(1, 'day');
        } else {
          dayjsDate = this.getDayjsDate().subtract(1, 'day');
        }

        break;
      case 'day':
        if (direction) {
          dayjsDate = this.getDayjsDate().add(1, 'month');
        } else {
          dayjsDate = this.getDayjsDate().subtract(1, 'month');
        }

        break;
      case 'month':
        if (direction) {
          dayjsDate = this.getDayjsDate().add(1, 'year');
        } else {
          dayjsDate = this.getDayjsDate().subtract(1, 'year');
        }

        break;
      case 'year':
        if (direction) {
          dayjsDate = this.getDayjsDate().add(10, 'year');
        } else {
          dayjsDate = this.getDayjsDate().subtract(10, 'year');
        }

        break;
      default:
        // eslint-disable-next-line no-restricted-syntax
        console.debug('Unknown direction');

        break;
    }

    this.yearCurrent = dayjsDate.get('year');
    this.monthCurrent = dayjsDate.get('month');
    this.dayCurrent = dayjsDate.date();
    this.timeCurrent = dayjsDate.get('hour');

    this.getYearList();
    this.getMonthList();
    this.getDayList();
    this.getTimeList();

    this.setDisablePreviousViewNavigation();
  }

  // prettier-ignore
  onViewChange(view: "day" | "month" | "year" | "time", event: Event | null): void {
    if (event?.stopPropagation) {
      event.stopPropagation();
    }

    this.calendarView = view;

    this.setDisablePreviousViewNavigation();
  }

  // prettier-ignore
  onSelectYear(year: number, event: Event): void {
    this.yearCurrent = year;

    if (this.endpointMode !== 'year') {
      this.getMonthList();
      this.onViewChange('month', event);
    } else {
      const dayjsDate: dayjs.Dayjs = this.getDayjsDate().set('month', 0).set('date', 1);

      this.yearSelected = dayjsDate.get('year');
      this.monthSelected = dayjsDate.get('month');
      this.daySelected = dayjsDate.date();

      this.selected.emit(dayjsDate);
    }
  }

  onSelectMonth(month: number, event: Event): void {
    this.monthCurrent = month;

    if (this.endpointMode !== 'month') {
      this.getDayList();
      this.onViewChange('day', event);
    } else {
      const dayjsDate: dayjs.Dayjs = this.getDayjsDate().set('date', 1);

      this.yearSelected = dayjsDate.get('year');
      this.monthSelected = dayjsDate.get('month');
      this.daySelected = dayjsDate.date();

      this.selected.emit(dayjsDate);
    }
  }

  onSelectDay(day: number, event: Event): void {
    this.dayCurrent = day;

    if (this.endpointMode === 'time') {
      this.getTimeList();
      this.onViewChange('time', event);
    } else {
      const dayjsDate: dayjs.Dayjs = this.getDayjsDate();

      this.yearSelected = dayjsDate.get('year');
      this.monthSelected = dayjsDate.get('month');
      this.daySelected = dayjsDate.date();

      this.selected.emit(dayjsDate);
    }
  }

  onSelectTime(time: number): void {
    this.timeCurrent = time;

    const dayjsDate: dayjs.Dayjs = this.getDayjsDate();

    this.yearSelected = dayjsDate.get('year');
    this.monthSelected = dayjsDate.get('month');
    this.daySelected = dayjsDate.date();
    this.timeSelected = dayjsDate.get('hour');

    this.selected.emit(dayjsDate);
  }

  getYearList(): void {
    this.yearList = [];

    this.yearList = Array.from(Array(12).keys()).map(
      (year: number, key: number) => {
        key++;

        let value: number;

        if (key < 5) {
          value = this.yearCurrent - (5 - key);
        } else if (key > 5) {
          value = this.yearCurrent + (key - 5);
        } else {
          value = this.yearCurrent;
        }

        const isSelected: boolean = value === this.yearSelected;
        const isToday: boolean = value === this.yearToday;

        return { value, isToday, isSelected };
      },
    );
  }

  getMonthList(): void {
    this.monthList = [];

    // prettier-ignore
    for (let i = 0; i < 12; i++) {
      const isSelected: boolean = i === this.monthSelected && this.yearCurrent === this.yearSelected;
      const isToday: boolean = i === this.monthToday && this.yearCurrent === this.yearToday;

      this.monthList.push({ value: i, isToday, isSelected });
    }
  }

  getDayList(): void {
    this.dayList = [[], [], []];

    const dayjsDate: dayjs.Dayjs = this.getDayjsDate();
    // prettier-ignore
    const firstDayOfMonth = dayjsDate.startOf('month').weekday() !== 0
      ? dayjsDate.startOf('month').weekday() - 1
      : 6;
    const daysInMonth: number = dayjsDate.daysInMonth();
    const daysInMonthPrevious: number = dayjsDate
      .subtract(1, 'month')
      .daysInMonth();

    const monthPrevious: StandaloneDropdownDatepickerButtonItems[] = [];
    const monthCurrent: StandaloneDropdownDatepickerButtonItems[] = [];
    const monthNext: StandaloneDropdownDatepickerButtonItems[] = [];

    const grid: any[] = Array(42).fill(0);

    grid.forEach((value: number, key: number) => {
      const day: number = key + 1 - firstDayOfMonth;

      // prettier-ignore
      if (key >= firstDayOfMonth && day <= daysInMonth) {
        // tslint:disable-next-line:max-line-length
        const isSelected: boolean = day === this.daySelected && this.monthCurrent === this.monthSelected && this.yearCurrent === this.yearSelected;

        const isToday: boolean = day === this.dayToday && this.monthCurrent === this.monthToday && this.yearCurrent === this.yearToday;

        monthCurrent.push({ value: day, isToday, isSelected });

        const week: number = 7;

        if (firstDayOfMonth === 0 && key < week) {
          monthPrevious.push({ value: daysInMonthPrevious - week + key + 1, isToday: false, isSelected: false });

          grid.pop();
        }

        if (this.disablePrevious) {
          // tslint:disable-next-line:no-shadowed-variable
          const dayjsDate: dayjs.Dayjs = this.getDayjsDate(day + 1);

          if (dayjsDate.subtract(1, 'day').isBefore(this.calendarCurrent)) {
            monthPrevious.push({ value: day, isToday: false, isSelected: false });

            monthCurrent.shift();
          }
        }
      } else if (key < firstDayOfMonth) {
        monthPrevious.push({ value: daysInMonthPrevious - firstDayOfMonth + key + 1, isToday: false, isSelected: false });
      } else {
        monthNext.push({
          value: key >= daysInMonth ? monthNext[monthNext.length - 1]?.value + 1 || 1 : 1,
          isToday: false,
          isSelected: false
        });
      }
    });

    this.dayList = [monthPrevious, monthCurrent, monthNext];
  }

  getTimeList(): void {
    this.timeList = [];

    for (let i: number = 0; i < 24; i++) {
      const isSelected: boolean =
        i === this.timeSelected &&
        this.dayCurrent === this.daySelected &&
        this.monthCurrent === this.monthSelected &&
        this.yearCurrent === this.yearSelected;

      const isToday: boolean =
        i === this.timeToday &&
        this.dayCurrent === this.dayToday &&
        this.monthCurrent === this.monthToday &&
        this.yearCurrent === this.yearToday;

      this.timeList.push({ value: i, isToday, isSelected });
    }
  }

  getDayjsDate(date: number = this.dayCurrent): dayjs.Dayjs {
    return dayjs()
      .set('year', this.yearCurrent)
      .set('month', this.monthCurrent)
      .set('date', date)
      .set('hour', this.timeCurrent)
      .set('minute', 0)
      .set('second', 0);
  }

  setDisablePreviousViewNavigation(): void {
    if (this.disablePrevious) {
      this.disablePreviousViewNavigation = ((view: string): boolean => {
        if (view === 'day' || view === 'month') {
          return (
            this.monthCurrent <= this.monthToday &&
            this.yearCurrent === this.yearToday
          );
        }

        return view === 'year' && this.yearCurrent <= this.yearToday;
      })(this.calendarView);
    }
  }
}
