<div
  class="flex flex-col !p-6 rounded-lg bg-default-neutral-2 hover:bg-active-neutral-2 duration-300 cursor-pointer"
  (click)="isShow = !isShow"
>
  <div class="flex items-center justify-between">
    <span
      class="text-interface-title-4-semibold text-default-neutral-1 !leading-6"
    >
      {{ title }}
    </span>
    <button class="kw-btn kw-btn-lg kw-btn-active kw-btn-on-light !p-0">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="currentColor"
        class="duration-300"
        [ngClass]="isShow ? 'rotate-45' : ''"
      >
        <path
          d="M19 11L13 11L13 5C13 4.73478 12.8946 4.48043 12.7071 4.29289C12.5196 4.10536 12.2652 4 12 4C11.7348 4 11.4804 4.10536 11.2929 4.29289C11.1054 4.48043 11 4.73478 11 5V11H5C4.73478 11 4.48043 11.1054 4.29289 11.2929C4.10536 11.4804 4 11.7348 4 12C4 12.2652 4.10536 12.5196 4.29289 12.7071C4.48043 12.8946 4.73478 13 5 13L11 13L11 19C11 19.2652 11.1054 19.5196 11.2929 19.7071C11.4804 19.8946 11.7348 20 12 20C12.2652 20 12.5196 19.8946 12.7071 19.7071C12.8946 19.5196 13 19.2652 13 19V13H19C19.2652 13 19.5196 12.8946 19.7071 12.7071C19.8946 12.5196 20 12.2652 20 12C20 11.7348 19.8946 11.4804 19.7071 11.2929C19.5196 11.1054 19.2652 11 19 11Z"
        />
      </svg>
    </button>
  </div>
  <!-- prettier-ignore -->
  <div
    #detailContent
    class="overflow-hidden transition-all duration-500"
    [ngClass]="heightContent !== 0 ? isShow ? '!mt-4' : '' : ''"
    [ngStyle]="{ height: heightContent !== 0 ? isShow ? heightContent + 'px' : '0px' : 'auto' }"
  >
    <ng-content select="[slot=content]"></ng-content>
  </div>
</div>
