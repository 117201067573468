import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import {
  SubscriptionChangeTo,
  SubscriptionsNew,
} from '../../models/subscriptions-new.model';
import { SubscriptionDialogService } from '../../services/dialog.service';
import { SubscriptionChangeService } from '../../services/subscription-change.service';
import {
  ChangeTariff,
  MeteredUsageInfo,
  Tariff,
  TariffPrice,
} from '../../../../premium-new/models/premium-new.model';
import { first, from } from 'rxjs';
import { MainHttpClient } from '../../../../../../services/main-http-client.service';

@Component({
  selector: 'app-subscription-cancel-dialog',
  templateUrl: './subscription-change-dialog.component.html',
})
export class SubscriptionChangeDialogComponent implements OnInit {
  mySubscriptions: SubscriptionsNew;

  tariffList: Tariff[] = [];
  priceMonth: TariffPrice | undefined;
  priceYear: TariffPrice | undefined;
  pricePayIfYouWinMonth: TariffPrice | undefined;
  daysInMonth: number = 0;

  nowDay: Date = new Date();
  nextPaymentDate: Date | undefined = undefined;

  nextSubscriptionPaymentDate: Date | undefined = undefined;
  nextSubscriptionPaymentPrice: number = 0;

  changeTo: SubscriptionChangeTo;

  leftDaysOfTrial: number = 0;

  isDisabledChangeBtn: boolean = false;

  tariffNameFrom: string = '';
  tariffNameTo: string = '';

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: {
      mySubscriptions: SubscriptionsNew;
      meteredUsageInfo: MeteredUsageInfo | undefined;
      tariffList: Tariff[];
      changeTo: SubscriptionChangeTo;
      tariffNameFrom: string;
      tariffNameTo: string;
    },
    private dialog: MatDialogRef<SubscriptionChangeDialogComponent>,
    private dialogService: SubscriptionDialogService,
    private subscriptionChangeService: SubscriptionChangeService,
    private http: MainHttpClient,
  ) {}

  // prettier-ignore
  ngOnInit(): void {
    this.getDaysInMonth();

    this.mySubscriptions = this.data.mySubscriptions;

    this.tariffList = this.data.tariffList;

    this.tariffNameFrom = this.data.tariffNameFrom;
    this.tariffNameTo = this.data.tariffNameTo;

    const priceList: TariffPrice[] = [];
    this.tariffList.forEach((tariff: Tariff) => {
      priceList.push(...tariff.prices);
    });

    let tariffName: string = '';
    // prettier-ignore
    switch (this.tariffNameTo) {
      case 'Pro':
        tariffName = 'pro_';
        break;
      case 'Premium':
        tariffName = 'premium_';
        break;
      case 'Ultimate':
        tariffName = 'ultimate_';
        break;
      default:
        tariffName = 'pro_';
        break;
    }

    priceList.forEach((priceTariff: TariffPrice) => {
      if (priceTariff.lookup_key.includes(tariffName)) {
        if (priceTariff.usage_type === 'licensed') {
          if (priceTariff.interval === 'month') {
            this.priceMonth = priceTariff;
          }

          if (priceTariff.interval !== 'month') {
            this.priceYear = priceTariff;
          }
        }

        if (priceTariff.usage_type === 'metered') {
          this.pricePayIfYouWinMonth = priceTariff;
        }
      }
    });

    this.changeTo = this.data.changeTo;

    this.nextPaymentDate = this.mySubscriptions.next_payment ? new Date(this.mySubscriptions.next_payment.date) : undefined;

    if (this.nextPaymentDate) {
      this.leftDaysOfTrial = Math.ceil((this.nextPaymentDate.getTime() - new Date().getTime()) / 1000 / 86400);
    }

    if(this.mySubscriptions.next_payment) {
      switch (this.changeTo) {
        case 'Month->Year':
          ((): void => {
            const nextDate: Date = new Date(this.mySubscriptions.next_payment.date);
            this.nextSubscriptionPaymentDate = new Date(nextDate.setFullYear(nextDate.getFullYear() + 1));
          })();
          break;
        case 'Month->PayIfYouWin':
          ((): void => {
            const restDay: number = this.daysInMonth - new Date().getDay();

            this.nextSubscriptionPaymentPrice = restDay * (this.pricePayIfYouWinMonth?.amount || 0);
          })();
          break;
        case 'Year->Month':
          ((): void => {
            const nextDate: Date = new Date(this.mySubscriptions.next_payment.date);
            this.nextSubscriptionPaymentDate = new Date(nextDate.setDate(nextDate.getDate() + 1))
          })();
          break;
        case 'PayIfYouWin->Month':
        case 'PayIfYouWin->Year':
          ((): void => {
            this.nextSubscriptionPaymentDate = new Date(new Date().setDate(new Date().getDate() + 1));

            const amountSpent: number = (this.pricePayIfYouWinMonth?.amount || 0) * new Date().getDate();
            this.nextSubscriptionPaymentPrice = Number(this.priceYear?.amount) - amountSpent;
          })();
          break;
        default:
          break;
      }
    }
  }

  // prettier-ignore
  getDaysInMonth(): void {
    const currentDate: Date = new Date();

    currentDate.setMonth(currentDate.getMonth() + 1, 0);

    this.daysInMonth = currentDate.getDate();
  }

  close(): void {
    this.dialog.close();
  }

  // prettier-ignore
  getChangePlan(changeToTariff: TariffPrice, tariffName: string): void {
    this.isDisabledChangeBtn = true;

    let tariffType: string = '';
    // prettier-ignore
    switch (true) {
      case changeToTariff.lookup_key.includes('pro_'):
        tariffType = 'Pro';
        break;
      case changeToTariff.lookup_key.includes('premium_'):
        tariffType = 'Premium';
        break;
      case changeToTariff.lookup_key.includes('ultimate_'):
        tariffType = 'Ultimate';
        break;
      default:
        tariffType = 'Pro';
        break;
    }

    from(this.http.post<{ data: ChangeTariff }>('/v2/changePlan', { new_price_id: changeToTariff.id }))
      .pipe(first())
      .subscribe({
        next: () => {
          this.close();
          this.isDisabledChangeBtn = false;

          this.subscriptionChangeService.currentChangeSubject.next('change');

          this.dialogService.openChangedSubscription({ tariffName, tariffType });
        },
        error: () => {
          this.close();
          this.isDisabledChangeBtn = false;
        },
      });
  }

  // prettier-ignore
  onChangePlan(): void {
    switch (this.changeTo) {
      case 'Year->Month':
      case 'PayIfYouWin->Month':
        this.priceMonth && this.getChangePlan(this.priceMonth, 'Flat Fee Monthly');
        break;
      case 'Month->Year':
      case 'PayIfYouWin->Year':
        this.priceYear && this.getChangePlan(this.priceYear, 'Flat Fee Annually');
        break;
      case 'Month->PayIfYouWin':
      case 'Year->PayIfYouWin':
        this.pricePayIfYouWinMonth && this.getChangePlan(this.pricePayIfYouWinMonth, 'Pay If You Win');
        break;
    }
  }

  protected readonly Number = Number;
}
