<div
  class="flex flex-col items-center justify-center !gap-8"
  [ngStyle]="{ height }"
>
  <div class="flex animate-spin">
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="48"
      height="48"
      viewBox="0 0 48 48"
      fill="none"
    >
      <g clip-path="url(#clip0_8148_20236)">
        <circle cx="24" cy="4.5" r="4.5" fill="#303D50" fill-opacity="0.1" />
        <circle
          opacity="0.45"
          cx="24"
          cy="43.5"
          r="4.5"
          fill="#303D50"
          fill-opacity="0.65"
        />
        <circle cx="4.5" cy="24" r="4.5" fill="#303D50" fill-opacity="0.85" />
        <circle cx="43.5" cy="24" r="4.5" fill="#303D50" fill-opacity="0.35" />
        <circle cx="39" cy="10.5" r="4.5" fill="#303D50" fill-opacity="0.25" />
        <circle cx="39" cy="37.5" r="4.5" fill="#303D50" fill-opacity="0.45" />
        <circle cx="9" cy="37.5" r="4.5" fill="#303D50" fill-opacity="0.75" />
        <circle cx="9" cy="10.5" r="4.5" fill="#303D50" />
      </g>
      <defs>
        <clipPath id="clip0_8148_20236">
          <rect width="48" height="48" fill="white" />
        </clipPath>
      </defs>
    </svg>
  </div>
  <span class="text-interface-title-4-regular text-default-neutral-3">
    Loading...
  </span>
</div>
