import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthorizedGuard } from '../../auth/guards/authorized.guard';
import { SorryPageComponent } from './pages/sorry-page/sorry-page.component';
import { CalendlyComponent } from './pages/calendly/calendly.component';
import { SubscriptionsNewComponent } from './subscriptions-new/subscriptions-new.component';
import { CommonModule } from '@angular/common';

const routes: Routes = [
  {
    path: 'subscriptions',
    pathMatch: 'full',
    component: SubscriptionsNewComponent,
    canActivate: [AuthorizedGuard],
  },
  {
    path: 'calendly',
    component: CalendlyComponent,
    canActivate: [AuthorizedGuard],
  },
  {
    path: 'sorry',
    component: SorryPageComponent,
    canActivate: [AuthorizedGuard],
  },
];

@NgModule({
  declarations: [SubscriptionsNewComponent],
  imports: [RouterModule.forChild(routes), CommonModule],
  exports: [RouterModule],
})
export class ProfileRoutingModule {}
