import { Injectable } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { AuthFormComponent } from "../components/auth-form/auth-form.component";
import { AuthFormParametersModel } from "../models/auth-form-parameters.model";
import { AuthFormTypeModel } from "../models/auth-form-type.model";

@Injectable({
    providedIn: 'root'
})
export class AuthFormService {

    private openned: boolean = false;

    private readonly panelClass: string = 'auth-form-dialog'

    public get IsFormOpenned(): boolean {
        return this.openned;
    }

    constructor(private dialog: MatDialog) {}


    openSignIn(params?: AuthFormParametersModel) {
        this.openned = true;
        this.dialog.open(AuthFormComponent, {
            data: {
              type: AuthFormTypeModel.SignIn,
              parameters: params
            },
            panelClass: this.panelClass
        }).afterClosed().subscribe(x => this.openned = false);
    }

    openSignUp(params?: AuthFormParametersModel) {
        this.openned = true;
        this.dialog.open(AuthFormComponent, {
            data: {
              type: AuthFormTypeModel.SignUp,
              parameters: params
            },
            panelClass: this.panelClass
        }).afterClosed().subscribe(x => this.openned = false);
    }

    openResetPassword(params?: AuthFormParametersModel) {
        this.openned = true;
        this.dialog.open(AuthFormComponent, {
            data: {
              type: AuthFormTypeModel.ResetPassword,
              parameters: params
            },
            panelClass: this.panelClass
        }).afterClosed().subscribe(x => this.openned = false);
    }
}
