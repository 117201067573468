import { AfterViewInit, Component, OnDestroy, OnInit } from '@angular/core';
import { AuthService } from './modules/auth/services/auth.service';
import { UtmService } from './modules/shared/modules/utm/services/utm.service';
import { EventsService, EventType } from './services/events.service';
import { Router } from '@angular/router';
import { TurnDeviceService } from './services/turn-device.service';
import { Subscription } from 'rxjs';
import { LinkMinkService } from './modules/affiliate/services/LinkMink.service';
import { TuneAffiliateService } from './modules/affiliate/services/TuneAffiliateService';
import { ExternalSignIn } from './modules/auth/services/ExternalSignIn';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements AfterViewInit, OnInit, OnDestroy {
  turnDeviceService$: Subscription;

  showSignUp: boolean = false;

  showTurnDevice: boolean = false;
  isBlockedShowTurnDevice: boolean = false;

  constructor(
    private authService: AuthService,
    private eventService: EventsService,
    private utmService: UtmService,
    private router: Router,
    private turnDeviceService: TurnDeviceService,
    private linkMinkService: LinkMinkService,
    private tuneAffiliateService: TuneAffiliateService,
    externalSignIn: ExternalSignIn,
  ) {
    if (externalSignIn.requested()) {
      externalSignIn.handle();
    }
  }

  async ngOnInit(): Promise<void> {
    this.turnDeviceService$ = this.turnDeviceService.turnSubject.subscribe(
      (isTurnDevice: boolean) => {
        this.showTurnDevice = isTurnDevice;
      },
    );

    if (!(await this.authService.isAuthenticated())) {
      this.showSignUp = true;
    }
  }

  async ngAfterViewInit(): Promise<void> {
    this.eventService.emit(EventType.PageResized);
    this.collectUtm();
    if (await this.linkMinkService.isReferral()) {
      this.linkMinkService.retrieveAndSaveReferralDataToLocalStorage();
    }

    if (await this.tuneAffiliateService.isAffiliateLink()) {
      this.tuneAffiliateService.retrieveDataFromAffiliateLinkAndStoreIt();
    }
  }

  ngOnDestroy(): void {
    [this.turnDeviceService$].forEach(($: Subscription) => $?.unsubscribe());
  }

  public async collectUtm(): Promise<void> {
    const utm = await this.utmService.createUtm();
    this.utmService.setUtmData(utm);
  }

  onResize(): void {
    this.eventService.emit(EventType.PageResized);

    /** FOR TURN DEVICE */
    const scale: number = window.innerWidth / 1636;

    if (scale < 1) {
      const screenWidth: number = window.screen.width;
      const screenHeight: number = window.screen.height;

      const isTargetUrl: boolean = ['events-timeline', 'single-stock'].some(
        (path: string) => this.router.url.includes(path),
      );

      // prettier-ignore
      this.turnDeviceService.turnSubject.next(screenWidth < screenHeight && isTargetUrl);
    }
  }
}
