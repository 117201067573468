import { Injectable } from '@angular/core';
import { GetUserPermissionsResponse } from 'src/app/interfaces/api/auth/get-user-permissions';
import { ResetPasswordRequest } from 'src/app/interfaces/api/auth/reset-password';
import { MainHttpClient } from 'src/app/services/main-http-client.service';
import {
  LoginRequest,
  LoginResponse,
} from '../../../interfaces/api/auth/login';
import {
  RegistrationRequest,
  RegistrationResponse,
} from '../../../interfaces/api/auth/registration';
import { JWTPayloadModel } from '../../shared/models/jwt-playload.modet';
import { UserTypeModel } from '../../shared/models/user-type.model';
import jwt_decode from 'jwt-decode';
import { ShareHttpResultService } from 'src/app/services/share-http-result.service';
import {
  SubscriptionFree,
  SubscriptionsNew,
} from '../../main/profile/subscriptions-new/models/subscriptions-new.model';
import { BehaviorSubject } from 'rxjs';

interface UserTokens {
  token: string;
  refreshToken: string;
}

@Injectable()
export class AuthService {
  private tokenStorageName: string = 'token';
  private refreshTokenStorageName: string = 'refresh-token';

  // prettier-ignore
  currentSubscription: BehaviorSubject<Promise<{ data: SubscriptionsNew | SubscriptionFree }> | undefined> = new BehaviorSubject<Promise<{ data: SubscriptionsNew | SubscriptionFree }> | undefined>(undefined);
  // prettier-ignore
  lastUpdateCurrentSubscription: BehaviorSubject<number> = new BehaviorSubject<number>(0);

  constructor(
    private http: MainHttpClient,
    private shareHttpResultService: ShareHttpResultService,
  ) {}

  async isAuthenticated(): Promise<boolean> {
    let payload = this.getPayload();
    if (payload === null) {
      return false;
    }
    const now = new Date().getTime();

    const expDate = new Date(0);
    expDate.setUTCSeconds(payload.exp);
    const exp = expDate.getTime();

    if (now > exp) {
      const refreshToken = this.getRefreshToken();
      if (refreshToken === null) {
        console.error('Failed to get refresh token');
        return false;
      }

      try {
        const tokens = await this.refreshToken(refreshToken);
        await this.setTokens(tokens.token, tokens.token);
      } catch (e) {
        return false;
      }
    }
    return true;
  }

  getToken(): string | null {
    return localStorage.getItem(this.tokenStorageName);
  }

  getPayload(): JWTPayloadModel | null {
    const token = this.getToken();
    if (token === null) {
      return null;
    }

    let payload: JWTPayloadModel | null = null;
    try {
      payload = jwt_decode<JWTPayloadModel>(token);
    } catch {}

    return payload;
  }

  getRefreshToken(): string | null {
    return localStorage.getItem(this.refreshTokenStorageName);
  }

  async setTokens(token: string, refreshToken: string) {
    localStorage.setItem(this.tokenStorageName, token);
    localStorage.setItem(this.refreshTokenStorageName, refreshToken);
    await this.refreshLocalPermissions();
  }

  removeTokens() {
    localStorage.removeItem(this.tokenStorageName);
    localStorage.removeItem(this.refreshTokenStorageName);
  }

  async login(data: LoginRequest): Promise<LoginResponse> {
    const response = await this.http.post<LoginResponse>(`/auth/login`, data);
    if (response.token !== undefined) {
      await this.setTokens(response.token, response.refreshToken);
      return response;
    }

    throw new Error((response as any).message);
  }

  async getPermissions(): Promise<Array<string>> {
    if (!(await this.isAuthenticated())) {
      return [];
    }

    const key = 'auth-service:get-permissions';
    const response = await this.shareHttpResultService.invoke(key, () =>
      this.http.post<GetUserPermissionsResponse>(`/v2/get-permissions`),
    );
    return response.permissions;
  }

  async refreshLocalPermissions(): Promise<GetUserPermissionsResponse> {
    if (!(await this.isAuthenticated())) {
      return {
        permissions: [],
      };
    }

    const response = await this.http.post<GetUserPermissionsResponse>(
      `/v2/get-permissions`,
    );
    return response;
  }

  // prettier-ignore
  async hasPremiumSubscription(): Promise<boolean> {
    const permissions: string[] = await this.getPermissions();
    const premiumSubscription: string | undefined = permissions.find((x: string): boolean => x === 'администратор' || x === 'premium.pro' || x === 'premium.ultra');

    return premiumSubscription !== undefined;
  }

  async logout(): Promise<void> {
    this.removeTokens();
    this.refreshLocalPermissions();
    localStorage.removeItem('email-alert-activated');
    location.reload();
  }

  async registration(data: RegistrationRequest): Promise<RegistrationResponse> {
    data.template = 'registration';
    const response = await this.http.post<RegistrationResponse>(
      `/auth/registration`,
      data,
    );
    await this.setTokens(response.token, response.refreshToken);

    /** @todo set refresh token */

    return response;
  }

  async resetPassword(data: ResetPasswordRequest): Promise<void> {
    await this.http.post<RegistrationResponse>(`/auth/reset-password`, data);
  }

  // prettier-ignore
  async getSubscriptions(): Promise<{ data: SubscriptionsNew | SubscriptionFree }> {
    const promiseSubscription: Promise<{ data: SubscriptionsNew | SubscriptionFree }> | undefined = this.currentSubscription.getValue();
    const lastTime: number = this.lastUpdateCurrentSubscription.getValue();

    const updateTime: number = 1000 * 20; // 20 sec

    if (promiseSubscription === undefined || new Date().getTime() - lastTime > updateTime ) {
      this.lastUpdateCurrentSubscription.next(new Date().getTime());

      this.currentSubscription.next(this.http.get<{ data: SubscriptionsNew | SubscriptionFree }>('/v2/subscription/info'));

      const promiseSubscription: Promise<{ data: SubscriptionsNew | SubscriptionFree}> | undefined = this.currentSubscription.getValue();

      return promiseSubscription !== undefined ? promiseSubscription : new Promise(resolve => {})
    } else {
      return  promiseSubscription;
    }
  }

  // prettier-ignore
  async getUserType(): Promise<UserTypeModel> {
    try {
      const mySubscriptions: { data: SubscriptionsNew | SubscriptionFree } = await this.getSubscriptions();

      if (!(await this.isAuthenticated())) {
        return UserTypeModel.Unregistered;
      } else {
        /** LOGGED */
        if ('is_trial_available' in mySubscriptions.data) {
          return mySubscriptions.data.is_trial_available ? UserTypeModel.Logged : UserTypeModel.WithTrialExpired;
        } else {
          switch (true) {
            case mySubscriptions.data.permissions.includes('ultimate'):
              return UserTypeModel.WithUltimate;
            case mySubscriptions.data.permissions.includes('premium'):
              return UserTypeModel.WithPremium;
            case mySubscriptions.data.permissions.includes('pro'):
              return UserTypeModel.WithPro;
            /** TRIAL */
            case mySubscriptions.data.trial_ends_at && !mySubscriptions.data.is_trial_expired:
              return UserTypeModel.WithTrial;
            default:
              return UserTypeModel.Logged;
          }
        }
      }
    } catch (e) {
      return UserTypeModel.Unregistered;
    }
  }

  refreshToken(token: string): Promise<UserTokens> {
    return this.http.post<UserTokens>(`/auth/refresh-token`, {
      token: token,
    });
  }
}
