<div class="flex flex-col !gap-2 w-[312px] !p-4">
  <!-- top view -->
  <div class="flex items-center justify-between w-full">
    <button
      class="kw-btn kw-btn-md kw-btn-active kw-btn-on-light"
      type="button"
      [disabled]="disablePreviousViewNavigation"
      (click)="onViewNavigate(false, $event)"
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="currentColor"
      >
        <path
          d="M13.0893 3.57745C13.4147 3.90289 13.4147 4.43053 13.0893 4.75596L7.84522 10L13.0893 15.2441C13.4147 15.5696 13.4147 16.0972 13.0893 16.4226C12.7639 16.7481 12.2362 16.7481 11.9108 16.4226L6.07745 10.5893C5.75201 10.2639 5.75201 9.73622 6.07745 9.41079L11.9108 3.57745C12.2362 3.25201 12.7639 3.25201 13.0893 3.57745Z"
        />
      </svg>
    </button>
    <ng-container [ngSwitch]="calendarView">
      <ng-container *ngSwitchCase="'time'">
        <button
          class="kw-btn kw-btn-md kw-btn-active kw-btn-on-light"
          type="button"
          (click)="onViewChange('day', $event)"
        >
          <span class="block">
            {{ dayCurrent }} {{ labelMonths[monthCurrent] }} {{ yearCurrent }}
          </span>
        </button>
      </ng-container>
      <ng-container *ngSwitchCase="'day'">
        <button
          class="kw-btn kw-btn-md kw-btn-active kw-btn-on-light"
          type="button"
          (click)="onViewChange('month', $event)"
        >
          <span class="block first-letter:uppercase">
            {{ labelMonths[monthCurrent] }} {{ yearCurrent }}
          </span>
        </button>
      </ng-container>
      <ng-container *ngSwitchCase="'month'">
        <button
          class="kw-btn kw-btn-md kw-btn-active kw-btn-on-light"
          type="button"
          (click)="onViewChange('year', $event)"
        >
          <span class="block">
            {{ yearCurrent }}
          </span>
        </button>
      </ng-container>
      <ng-container *ngSwitchCase="'year'">
        <!-- prettier-ignore -->
        <button
          class="kw-btn kw-btn-md kw-btn-active kw-btn-on-light"
          type="button"
          (click)="onViewChange('month',$event)"
        >
					<span class="block">
						{{ yearList[0].value }}&nbsp;-&nbsp;{{ yearList[yearList.length - 1].value}}
					</span>
        </button>
      </ng-container>
    </ng-container>
    <button
      class="kw-btn kw-btn-md kw-btn-active kw-btn-on-light"
      type="button"
      (click)="onViewNavigate(true, $event)"
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="currentColor"
      >
        <path
          d="M6.9107 3.57745C6.58527 3.90289 6.58527 4.43053 6.9107 4.75596L12.1548 10L6.9107 15.2441C6.58527 15.5696 6.58527 16.0972 6.9107 16.4226C7.23614 16.7481 7.76378 16.7481 8.08922 16.4226L13.9225 10.5893C14.248 10.2639 14.248 9.73622 13.9225 9.41079L8.08922 3.57745C7.76378 3.25201 7.23614 3.25201 6.9107 3.57745Z"
        />
      </svg>
    </button>
  </div>
  <ng-container [ngSwitch]="calendarView">
    <!-- TIME -->
    <ng-container *ngSwitchCase="'time'">
      <ul class="flex h-[280px] flex-wrap justify-between">
        <li class="flex items-center" *ngFor="let time of timeList">
          <!-- prettier-ignore -->
          <button
            class="kw-btn kw-btn-datepicker !w-[70px]"
            type="button"
            [ngClass]="time.isSelected ? 'kw-btn-selected' : time.isToday ? 'kw-btn-active' : ''"
            (click)="onSelectTime(time.value)"
          >
						<span class="block">
							{{ time.value < 10 ? "0" + time.value + ":00" : time.value + ":00" }}
						</span>
          </button>
        </li>
      </ul>
    </ng-container>
    <!-- DAY -->
    <ng-container *ngSwitchCase="'day'">
      <!--prettier-ignore-->
      <div class="flex flex-col">
        <ul class="flex">
          <li class="h-10 w-10 flex items-center justify-center" *ngFor="let dayLabel of labelDays; let index = index;">
            <span
              class="block text-interface-control-5-semibold text-center uppercase"
              [ngClass]="index > 4 ? 'text-default-tertiary-2' : 'text-default-neutral-1'"
            >
              {{ dayLabel }}
            </span>
          </li>
        </ul>
        <ul class="flex flex-wrap">
          <li *ngFor="let day of dayList[0]">
            <button class="kw-btn kw-btn-datepicker" type="button" [disabled]="true">
              <span class="block text-low">{{ day.value }}</span>
            </button>
          </li>
          <li *ngFor="let day of dayList[1]">
            <button
              class="kw-btn kw-btn-datepicker"
              type="button"
              [ngClass]="day.isSelected ? 'kw-btn-selected' : day.isToday ? 'kw-btn-active' : ''"
              (click)="onSelectDay(day.value, $event)"
            >
              <span class="block">{{ day.value }}</span>
            </button>
          </li>
          <li *ngFor="let day of dayList[2]">
            <button class="kw-btn kw-btn-datepicker" type="button" [disabled]="true">
              <span class="block text-low">{{ day.value }}</span>
            </button>
          </li>
        </ul>
      </div>
    </ng-container>
    <!-- MONTH -->
    <ng-container *ngSwitchCase="'month'">
      <ul class="flex flex-wrap justify-around h-[280px]">
        <li class="flex items-center" *ngFor="let month of monthList">
          <!-- prettier-ignore -->
          <button
            class="kw-btn kw-btn-datepicker !w-[70px]"
            type="button"
            [disabled]="disablePrevious && month.value < monthToday && yearCurrent === yearToday"
            [ngClass]="month.isSelected ? 'kw-btn-selected' : month.isToday ? 'kw-btn-active' : ''"
            (click)="onSelectMonth(month.value, $event)"
          >
						<span class="block first-letter:uppercase">
							{{ labelMonthsShort[month.value] }}
						</span>
          </button>
        </li>
      </ul>
    </ng-container>
    <!-- YEAR -->
    <ng-container *ngSwitchCase="'year'">
      <ul class="flex flex-wrap justify-around h-[280px]">
        <li class="flex items-center" *ngFor="let year of yearList">
          <!-- prettier-ignore -->
          <button
            class="kw-btn kw-btn-datepicker !w-[70px]"
            type="button"
            [disabled]="disablePrevious && year.value < yearToday"
            [ngClass]="year.isSelected ? 'kw-btn-selected' : year.isToday ? 'kw-btn-active' : ''"
            (click)="onSelectYear(year.value, $event)"
          >
						<span class="block">
							{{ year.value }}
						</span>
          </button>
        </li>
      </ul>
    </ng-container>
  </ng-container>
</div>
