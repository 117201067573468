/** @format */

import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import * as dayjs from 'dayjs';
import * as weekday from 'dayjs/plugin/weekday';
import * as localeData from 'dayjs/plugin/localeData';
import * as isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import { RangeDatepickerButtonItems } from '../../models/datepicker.model';
import * as updateLocale from 'dayjs/plugin/updateLocale';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';
import { HelperService } from '../../../../services/helper.service';

dayjs.extend(updateLocale);

dayjs.updateLocale('en', {
  monthsShort: [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec',
  ],
  weekdaysMin: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'],
});

dayjs.extend(weekday);
dayjs.extend(localeData);
dayjs.extend(isSameOrBefore);

@Component({
  selector: 'app-range-datepicker',
  templateUrl: './range-datepicker.component.html',
})
export class RangeDatepickerComponent implements OnInit, OnDestroy {
  // prettier-ignore
  @Output() selected: EventEmitter<[dayjs.Dayjs, dayjs.Dayjs] | null> = new EventEmitter<[dayjs.Dayjs, dayjs.Dayjs] | null>();

  @Input()
  set appSelectedDayjs(selectedDayjs: [dayjs.Dayjs, dayjs.Dayjs] | null) {
    // prettier-ignore
    if (selectedDayjs) {
      this.startYearSelected = selectedDayjs[0].get('year');
      this.startMonthSelected = selectedDayjs[0].get('month');
      this.startDaySelected = selectedDayjs[0].date();

      this.endYearSelected = selectedDayjs[1].get('year');
      this.endMonthSelected = selectedDayjs[1].get('month');
      this.endDaySelected = selectedDayjs[1].date();

      this.setDateFormat('start');
      this.setDateFormat('end');

      // prettier-ignore
      this.isReadySubmit = this.endDaySelected !== null && this.endMonthSelected !== null && this.endYearSelected !== null;

      this.getDayList();
		} else {
			this.startYearSelected = null;
			this.startMonthSelected = null;
			this.startDaySelected = null;

      this.endYearSelected = null;
      this.endMonthSelected = null;
      this.endDaySelected = null;
		}
  }

  @Input()
  set appDisableFutureDate(disableFutureDate: boolean) {
    this.disableFutureDate = disableFutureDate;
  }

  calendarCurrent: dayjs.Dayjs = dayjs();

  startYearSelected: number | null = null;
  startYearCurrent!: number;
  endYearSelected: number | null = null;
  endYearCurrent!: number;
  yearToday!: number;

  startMonthSelected: number | null = null;
  startMonthCurrent!: number;
  endMonthSelected: number | null = null;
  endMonthCurrent!: number;
  monthToday!: number;

  startDaySelected: number | null = null;
  startDayCurrent!: number;
  endDaySelected: number | null = null;
  endDayCurrent!: number;
  dayToday!: number;

  // startDateFormat!: string;
  // endDateFormat!: string;

  dayList: Array<RangeDatepickerButtonItems[][]> = [
    [[], [], []],
    [[], [], []],
  ];

  labelDays: string[] = dayjs.weekdaysMin(true);
  labelMonths: string[] = dayjs.months();

  isRangeClasses: string = 'kw-btn-selected';
  isStartClasses: string = 'kw-btn-start-selected';
  isEndClasses: string = 'kw-btn-end-selected';
  isTodayClasses: string = 'kw-btn-active';
  isDefaultClasses: string = 'isDefaultClasses';

  isReadySubmit!: boolean;

  disableFutureDate!: boolean;

  dateForm: FormGroup;
  dateFormStartDate$!: Subscription | undefined;
  dateFormEndDate$!: Subscription | undefined;

  constructor(
    private helperService: HelperService,
    private formBuilder: FormBuilder,
  ) {
    // prettier-ignore
    this.dateForm = this.formBuilder.group({
      startDate: this.formBuilder.control('', [this.helperService.getCustomValidation('isDate'), Validators.required]),
      endDate: this.formBuilder.control('', [this.helperService.getCustomValidation('isDate'), Validators.required]),
    });
  }

  // prettier-ignore
  ngOnInit(): void {
    this.yearToday = this.calendarCurrent.get('year');
    this.monthToday = this.calendarCurrent.get('month');
    this.dayToday = this.calendarCurrent.date();

    this.setDateCurrent(this.calendarCurrent);

    this.dateFormStartDate$ = this.dateForm.get('startDate')?.valueChanges.subscribe((value) => {
      if (this.dateForm.get('startDate')?.status === 'VALID') {
        const day: number = value.split('.')[0] || 0;
        const month: number = value.split('.')[1] - 1 || 0;
        const year: number = value.split('.')[2] || 0;

        if (this.disableFutureDate && dayjs().isBefore(this.getDayjsDate(day, month, year))) {
          this.setDateSelected('start', dayjs());
        } else {
          this.setDateSelected('start', this.getDayjsDate(day, month, year));
        }

        if (this.getDayjsDate(this.endDaySelected, this.endMonthSelected, this.endYearSelected).isBefore(this.getDayjsDate(day, month, year))) {
          this.endYearSelected = null;
          this.endMonthSelected = null;
          this.endDaySelected = null;

          this.dateForm.get('endDate')?.setValue('', { emitEvent: false });
        }

        this.getDayList();
      }
    });

    this.dateFormEndDate$ = this.dateForm.get('endDate')?.valueChanges.subscribe((value) => {
      if (this.dateForm.get('endDate')?.status === 'VALID') {
        const day: number = value.split('.')[0] || 0;
        const month: number = value.split('.')[1] - 1 || 0;
        const year: number = value.split('.')[2] || 0;

        if (this.disableFutureDate && dayjs().isBefore(this.getDayjsDate(day, month, year))) {
          this.setDateSelected('end', dayjs());
        } else {
          this.setDateSelected('end', this.getDayjsDate(day, month, year));
        }

        if (this.getDayjsDate(day, month, year).isBefore(this.getDayjsDate(this.startDaySelected, this.startMonthSelected, this.startYearSelected))) {
          this.endYearSelected = this.startYearSelected;
          this.endMonthSelected = this.startMonthSelected;
          this.endDaySelected = this.startDaySelected;

          this.dateForm.get('endDate')?.setValue(
            this.getDayjsDate(this.startDaySelected, this.startMonthSelected, this.startYearSelected).format('DD.MM.YYYY'),
            { emitEvent: false }
          );
        }

        this.getDayList();
      }
    });
	}

  ngOnDestroy(): void {
    [this.dateFormStartDate$].forEach(($: Subscription | undefined) =>
      $?.unsubscribe(),
    );
  }

  onViewNavigate(direction: boolean): void {
    let dayjsDate: dayjs.Dayjs;

    if (direction) {
      dayjsDate = this.getDayjsDate().add(1, 'month');
    } else {
      dayjsDate = this.getDayjsDate().subtract(1, 'month');
    }

    this.setDateCurrent(dayjsDate);
  }

  onSelectDay(day: number, side: string, event: MouseEvent): void {
    if (event?.stopPropagation) {
      event.stopPropagation();
    }

    // @ts-ignore
    this[side + 'DayCurrent'] = day;

    // prettier-ignore
    const isSelectedStartDate: boolean = this.startDaySelected !== null && this.startMonthSelected !== null && this.startYearSelected !== null;
    // prettier-ignore
    let isSelectedEndDate: boolean = this.endDaySelected !== null && this.endMonthSelected !== null && this.endYearSelected !== null;

    // prettier-ignore
    // @ts-ignore
    const newDateSelected: dayjs.Dayjs = this.getDayjsDate(this[side + 'DayCurrent'], this[side + 'MonthCurrent'], this[side + 'YearCurrent']);

    // prettier-ignore
    const oldStartDateSelected: dayjs.Dayjs = this.getDayjsDate(this.startDaySelected, this.startMonthSelected, this.startYearSelected);

    if (isSelectedStartDate && !isSelectedEndDate) {
      if (newDateSelected.isBefore(oldStartDateSelected)) {
        this.setDateSelected('start', newDateSelected);
      } else {
        this.setDateSelected('end', newDateSelected);
      }
    } else {
      this.setDateSelected('start', newDateSelected);

      this.endYearSelected = null;
      this.endMonthSelected = null;
      this.endDaySelected = null;

      // this.endDateFormat = '';
      this.dateForm.get('endDate')?.setValue('', { emitEvent: false });
    }

    this.getDayList();

    // prettier-ignore
    this.isReadySubmit = this.endDaySelected !== null && this.endMonthSelected !== null && this.endYearSelected !== null;
  }

  getDayList(): void {
    this.dayList = [];

    const gridAll = [Array(42).fill(0), Array(42).fill(0)];

    gridAll.forEach((grid: Array<number>, sideDatepicker: number) => {
      const side = sideDatepicker === 0 ? 'start' : 'end';

      // prettier-ignore
      const dayjsDate = side === 'start'
        ? this.getDayjsDate(this.startDayCurrent, this.startMonthCurrent, this.startYearCurrent)
        : this.getDayjsDate(this.endDayCurrent, this.endMonthCurrent, this.endYearCurrent);

      // prettier-ignore
      const firstDayOfMonth = dayjsDate.startOf('month').weekday() !== 0
          ? dayjsDate.startOf('month').weekday() - 1
          : 6;
      const daysInMonth = dayjsDate.daysInMonth();
      const daysInMonthPrevious = dayjsDate.subtract(1, 'month').daysInMonth();

      const monthPrevious: RangeDatepickerButtonItems[] = [];
      const monthCurrent: RangeDatepickerButtonItems[] = [];
      const monthNext: RangeDatepickerButtonItems[] = [];

      grid.forEach((value: number, key: number) => {
        const day = key + 1 - firstDayOfMonth;

        // prettier-ignore
        if (key >= firstDayOfMonth && day <= daysInMonth) {
          const isStart: boolean = this.getIsStart(day, side);
          const isEnd: boolean = this.getIsEnd(day, side);

          const inRange: boolean = this.getIsRange(day, side);

          // @ts-ignore
          const isToday: boolean = day === this.dayToday && this[side + 'MonthCurrent'] === this.monthToday && this[side + 'YearCurrent'] === this.yearToday;

          monthCurrent.push({value: day, isToday, isStart, isEnd, isRange: inRange});

          const week = 7;

          if (firstDayOfMonth === 0 && key < week) {
            monthPrevious.push({value: daysInMonthPrevious - week + key + 1, isToday: false, isStart: false, isEnd: false, isRange: false});

            grid.pop();
          }
        } else if (key < firstDayOfMonth) {
          monthPrevious.push({
            value: daysInMonthPrevious - firstDayOfMonth + key + 1,
            isToday: false, isStart: false, isEnd: false, isRange: false
          });
        } else {
          monthNext.push({
            value: key >= daysInMonth ? monthNext[monthNext.length - 1]?.value + 1 || 1 : 1,
            isToday: false, isStart: false, isEnd: false, isRange: false
          });
        }
      });

      this.dayList.push([monthPrevious, monthCurrent, monthNext]);
    });
  }

  getDayjsDate(
    date: number | null = this.endDayCurrent,
    month: number | null = this.endMonthCurrent,
    year: number | null = this.endYearCurrent,
  ): dayjs.Dayjs {
    return dayjs()
      .set('year', year || 0)
      .set('month', month || 0)
      .set('date', date || 0)
      .set('hour', 0)
      .set('minute', 0)
      .set('second', 0);
  }

  // prettier-ignore
  getIsStart(day: number, side: string): boolean {
		const dateSelected: dayjs.Dayjs = this.getDayjsDate(this.startDaySelected, this.startMonthSelected, this.startYearSelected);

    // @ts-ignore
    const dateCurrent: dayjs.Dayjs = this.getDayjsDate(day, this[side + 'MonthCurrent'], this[side + 'YearCurrent']);

		return dateSelected.diff(dateCurrent, 'hour') === 0;
	}

  // prettier-ignore
  getIsEnd(day: number, side: string): boolean {
    const dateSelected: dayjs.Dayjs = this.getDayjsDate(this.endDaySelected, this.endMonthSelected, this.endYearSelected);

    // @ts-ignore
    const dateCurrent: dayjs.Dayjs = this.getDayjsDate(day, this[side + 'MonthCurrent'], this[side + 'YearCurrent']);

    return dateSelected.diff(dateCurrent, 'hour') === 0;
	}

  getIsRange(day: number, side: string): boolean {
    // prettier-ignore
    const isSelectStartDate: boolean = this.startDaySelected !== null && this.startMonthSelected !== null && this.startYearSelected !== null;
    // prettier-ignore
    const isSelectEndDate: boolean = this.endDaySelected !== null && this.endMonthSelected !== null && this.endYearSelected !== null;

    // prettier-ignore
    if(isSelectStartDate && isSelectEndDate) {
      const startDate: dayjs.Dayjs = this.getDayjsDate(this.startDaySelected, this.startMonthSelected, this.startYearSelected);
      const endDate: dayjs.Dayjs = this.getDayjsDate(this.endDaySelected, this.endMonthSelected, this.endYearSelected);

      // @ts-ignore
      const dateCurrent: dayjs.Dayjs = this.getDayjsDate(day, this[side + 'MonthCurrent'], this[side + 'YearCurrent']);

      return dateCurrent.isAfter(startDate) && dateCurrent.isBefore(endDate);
    }

    return false;
  }

  // prettier-ignore
  setDateFormat(side: string): void {
    // @ts-ignore
    const dayjsDate: dayjs.Dayjs = this.getDayjsDate(this[side + 'DaySelected'], this[side + 'MonthSelected'], this[side + 'YearSelected']);

    if(side === 'start') {
      this.dateForm.get('startDate')?.setValue(dayjsDate.format('DD.MM.YYYY'), { emitEvent: false });
      // this.startDateFormat = dayjsDate.format('DD.MM.YYYY');
    } else {
      this.dateForm.get('endDate')?.setValue(dayjsDate.format('DD.MM.YYYY'), { emitEvent: false });
      // this.endDateFormat = dayjsDate.format('DD.MM.YYYY');
    }
  }

  setDateSelected(side: string, dayjs: dayjs.Dayjs): void {
    if (side === 'start') {
      this.startYearSelected = dayjs.year();
      this.startMonthSelected = dayjs.month();
      this.startDaySelected = dayjs.date();
    } else {
      this.endYearSelected = dayjs.year();
      this.endMonthSelected = dayjs.month();
      this.endDaySelected = dayjs.date();
    }

    this.setDateFormat(side);
  }

  setDateCurrent(dayjsDate: dayjs.Dayjs): void {
    this.startYearCurrent = dayjsDate.subtract(1, 'month').get('year');
    this.startMonthCurrent = dayjsDate.subtract(1, 'month').get('month');
    this.startDayCurrent = dayjsDate.subtract(1, 'month').date();

    this.endYearCurrent = dayjsDate.get('year');
    this.endMonthCurrent = dayjsDate.get('month');
    this.endDayCurrent = dayjsDate.date();

    this.getDayList();
  }

  // prettier-ignore
  onSubmit(event: MouseEvent): void {
    if (event?.preventDefault) {
      event.preventDefault();
    }

		const startDate: dayjs.Dayjs = this.getDayjsDate(this.startDaySelected, this.startMonthSelected, this.startYearSelected);

		const endDate: dayjs.Dayjs = this.getDayjsDate(this.endDaySelected, this.endMonthSelected, this.endYearSelected);

		this.selected.emit([startDate, endDate]);
	}

  onCancel(): void {
    this.selected.emit(null);
  }
}
