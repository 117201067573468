<div
  class="flex flex-col bg-white w-screen md:max-w-[480px] h-screen md:h-auto md:max-h-screen md:rounded-2xl shadow-shadow-modal-shadow"
>
  <div class="flex justify-end !p-4">
    <button (click)="close()" type="button">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
      >
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M10.7349 12L6 7.26512L7.26512 6L12 10.7349L16.7349 6L18 7.26512L13.2651 12L18 16.7349L16.7349 18L12 13.2651L7.26512 18L6 16.7349L10.7349 12Z"
          fill="#333333"
        />
      </svg>
    </button>
  </div>
  <div class="flex flex-col !gap-6 !p-8 !pt-0">
    <div class="flex flex-col !gap-6 max-h-[calc(90vh-128px)] overflow-y-auto">
      <div class="flex flex-col !gap-2">
        <span class="text-interface-title-3-semibold text-default-neutral-1">
          Change Subscription Plan?
        </span>
        <!-- TRIAL && CHANGE PLAN -->
        <p
          *ngIf="
            data.mySubscriptions.trial_ends_at &&
            !mySubscriptions.is_trial_expired
          "
          class="text-interface-body-1-regular text-default-neutral-1"
        >
          You will switch Subscription Plans within the same Free Trial period.
        </p>
        <!-- NOT TRIAL && CHANGE FLAT FEE MONTHLY TO ANNUALLY -->
        <!-- prettier-ignore -->
        <p
          *ngIf="mySubscriptions.is_trial_expired && changeTo === 'Month->Year'"
          class="text-interface-body-1-regular text-default-neutral-1"
        >
          Your {{ tariffNameFrom }} Flat Fee Monthly Plan will be cancelled and {{ tariffNameTo }} Flat Fee Annually Plan will be activated.
        </p>
        <!-- NOT TRIAL && CHANGE FLAT FEE MONTHLY TO PAY IF YOU WIN -->
        <!-- prettier-ignore -->
        <p
          *ngIf="mySubscriptions.is_trial_expired && changeTo === 'Month->PayIfYouWin'"
          class="text-interface-body-1-regular text-default-neutral-1"
        >
          Your {{ tariffNameFrom }} Flat Fee Plan will be valid till <br />
          {{ nextPaymentDate | date: 'MMM d, y' }}. After that {{ tariffNameTo }} Pay If You Win
          Plan will be activated. The number of days used on the {{ tariffNameFrom }} Flat Fee
          plan will be deducted from the first month of the {{ tariffNameTo }} Pay If you Win
          plan.
        </p>
        <!-- NOT TRIAL && CHANGE PAY IF YOU WIN TO FLAT FEE MONTHLY -->
        <!-- prettier-ignore -->
        <p
          *ngIf="(!data.mySubscriptions.trial_ends_at || data.mySubscriptions.trial_ends_at && data.mySubscriptions.is_trial_expired) && data.meteredUsageInfo && changeTo === 'PayIfYouWin->Month'"
          class="text-interface-body-1-regular text-default-neutral-1"
        >
          Your {{ tariffNameFrom }} Pay If You Win Plan will be cancelled and {{ tariffNameTo }} Flat Fee Plan
          will be activated.
          <span class="text-default-tertiary-2">You’ll charged for ${{ data.meteredUsageInfo.amount | number: '' }}</span>
          for {{ data.meteredUsageInfo.days_count }} days of using Pay If You Win subscription.
        </p>
        <!-- NOT TRIAL && CHANGE PAY IF YOU WIN TO FLAT FEE ANNUALLY -->
        <!-- prettier-ignore -->
        <p
          *ngIf="(!data.mySubscriptions.trial_ends_at || data.mySubscriptions.trial_ends_at && data.mySubscriptions.is_trial_expired) && changeTo === 'PayIfYouWin->Year'"
          class="text-interface-body-1-regular text-default-neutral-1"
        >
          Your {{ tariffNameFrom }} Pay If You Win Plan will be cancelled and {{ tariffNameTo }} Flat Fee Plan will be activated.
        </p>
        <!-- NOT TRIAL && CHANGE FLAT FEE ANNUALLY TO MONTHLY -->
        <!-- prettier-ignore -->
        <p
          *ngIf="mySubscriptions.is_trial_expired && changeTo === 'Year->Month'"
          class="text-interface-body-1-regular text-default-neutral-1"
        >
          Your {{ tariffNameFrom }} Flat Fee Annually Plan will be valid till <br />
          {{ nextPaymentDate | date: 'MMM d, y' }}.
          After that {{ tariffNameTo }} Flat Fee Monthly Plan will be activated.
        </p>
        <!-- NOT TRIAL && CHANGE FLAT FEE ANNUALLY TO PAY IF YOU WIN -->
        <!-- prettier-ignore -->
        <p
          *ngIf="mySubscriptions.is_trial_expired && changeTo === 'Year->PayIfYouWin'"
          class="text-interface-body-1-regular text-default-neutral-1"
        >
          Your {{ tariffNameFrom }} Flat Fee Annually Plan will be valid till <br />
          {{ nextPaymentDate | date: 'MMM d, y' }}.
          After that {{ tariffNameTo }} Pay If You Win Plan will be activated.
        </p>
      </div>
      <div
        *ngIf="
          (!data.mySubscriptions.trial_ends_at ||
            (data.mySubscriptions.trial_ends_at &&
              data.mySubscriptions.is_trial_expired)) &&
          mySubscriptions.price.usage_type === 'metered'
        "
        class="flex !p-4 bg-tertiary-1 rounded-lg"
      >
        <p class="text-interface-body-2-regular text-default-neutral-1">
          Please notice that
          <span class="text-default-tertiary-2">
            you won’t be able to request revision after canceling
          </span>
          your subscription. If you have already sent a request, it will be
          processed. <br />
          <span class="text-interface-body-2-semibold">
            If you have any questions, please contact us:
          </span>
          <button
            class="text-interface-body-2-semibold text-default-secondary-2 hover:text-default-secondary-2"
            type="button"
          >
            support@visualsectors.com
          </button>
        </p>
      </div>
      <div class="flex flex-col !gap-2 !p-4 rounded-lg bg-default-neutral-2">
        <span class="text-interface-body-2-semibold text-default-neutral-1">
          Current Subscription Terms:
        </span>
        <div class="flex flex-col">
          <!-- PLAN -->
          <ul class="flex items-center !gap-1">
            <li class="text-interface-body-2-semibold text-default-neutral-1">
              Plan:
            </li>
            <li class="text-interface-body-2-regular text-default-neutral-1">
              {{ tariffNameFrom }}
              {{
                mySubscriptions.price.usage_type === 'licensed'
                  ? 'Flat Fee'
                  : 'Pay If You Win'
              }}
            </li>
            <li class="text-interface-body-2-regular text-default-neutral-3">
              {{
                mySubscriptions.price.interval === 'month'
                  ? 'Monthly'
                  : 'Annually'
              }}
            </li>
          </ul>
          <!-- PRICE -->
          <ul class="flex items-center !gap-1">
            <li class="text-interface-body-2-semibold text-default-neutral-1">
              Price:
            </li>
            <!-- TRIAL EXPIRED -->
            <ng-container
              *ngIf="
                !data.mySubscriptions.trial_ends_at ||
                (data.mySubscriptions.trial_ends_at &&
                  mySubscriptions.is_trial_expired)
              "
            >
              <li class="text-interface-body-2-regular text-default-neutral-1">
                ${{ mySubscriptions.price.amount }}
              </li>
              <li class="text-interface-body-2-regular text-default-neutral-3">
                /{{ mySubscriptions.price.interval }}
              </li>
            </ng-container>
            <!-- TRIAL NOT EXPIRED -->
            <ng-container
              *ngIf="
                data.mySubscriptions.trial_ends_at &&
                !mySubscriptions.is_trial_expired
              "
            >
              <li class="text-interface-body-2-regular text-default-neutral-1">
                {{ leftDaysOfTrial }} Days Free Trial
              </li>
              <!-- prettier-ignore -->
              <li class="text-interface-body-2-regular text-default-neutral-3">
                Then ${{ mySubscriptions.price.amount }} /{{ mySubscriptions.price.interval }}
              </li>
            </ng-container>
          </ul>
          <!-- NEXT PAYMENT -->
          <ul class="flex items-center !gap-1">
            <li class="text-interface-body-2-semibold text-default-neutral-1">
              Next Payment:
            </li>
            <li class="text-interface-body-2-regular">
              On {{ nextPaymentDate | date: 'MMM d, y' }}
            </li>
          </ul>
        </div>
      </div>
      <div class="flex flex-col !gap-2 !p-4 rounded-lg bg-default-neutral-2">
        <span class="text-interface-body-2-semibold text-default-neutral-1">
          New Subscription Terms:
        </span>
        <div class="flex flex-col">
          <!-- PLAN -->
          <ul class="flex items-center !gap-1">
            <li class="text-interface-body-2-semibold text-default-neutral-1">
              Plan:
            </li>
            <!-- CHANGE FLAT FEE MONTHLY TO ANNUALLY -->
            <ng-container *ngIf="changeTo === 'Month->Year'">
              <li class="text-interface-body-2-regular text-default-neutral-1">
                {{ tariffNameTo }} Flat Fee
              </li>
              <li class="text-interface-body-2-regular text-default-neutral-3">
                Annually
              </li>
            </ng-container>
            <!-- CHANGE FLAT FEE MONTHLY TO PAY IF YOU WIN -->
            <ng-container *ngIf="changeTo === 'Month->PayIfYouWin'">
              <li class="text-interface-body-2-regular text-default-neutral-1">
                {{ tariffNameTo }} Pay If You Win
              </li>
              <li class="text-interface-body-2-regular text-default-neutral-3">
                Monthly
              </li>
            </ng-container>
            <!-- CHANGE PAY IF YOU WIN TO FLAT FEE MONTHLY -->
            <ng-container *ngIf="changeTo === 'PayIfYouWin->Month'">
              <li class="text-interface-body-2-regular text-default-neutral-1">
                {{ tariffNameTo }} Flat Fee
              </li>
              <li class="text-interface-body-2-regular text-default-neutral-3">
                Monthly
              </li>
            </ng-container>
            <!-- CHANGE PAY IF YOU WIN TO FLAT FEE ANNUALLY -->
            <ng-container *ngIf="changeTo === 'PayIfYouWin->Year'">
              <li class="text-interface-body-2-regular text-default-neutral-1">
                {{ tariffNameTo }} Flat Fee
              </li>
              <li class="text-interface-body-2-regular text-default-neutral-3">
                Annually
              </li>
            </ng-container>
            <!-- CHANGE FLAT FEE ANNUALLY TO FLAT FEE MONTHLY -->
            <ng-container *ngIf="changeTo === 'Year->Month'">
              <li class="text-interface-body-2-regular text-default-neutral-1">
                {{ tariffNameTo }} Flat Fee
              </li>
              <li class="text-interface-body-2-regular text-default-neutral-3">
                Monthly
              </li>
            </ng-container>
            <!-- CHANGE FLAT FEE ANNUALLY TO PAY IF YOU WIN -->
            <ng-container *ngIf="changeTo === 'Year->PayIfYouWin'">
              <li class="text-interface-body-2-regular text-default-neutral-1">
                {{ tariffNameTo }} Pay If You Win
              </li>
              <li class="text-interface-body-2-regular text-default-neutral-3">
                Monthly
              </li>
            </ng-container>
          </ul>
          <!-- PRICE -->
          <ul class="flex items-center !gap-1">
            <li class="text-interface-body-2-semibold text-default-neutral-1">
              Price:
            </li>
            <!-- TRIAL EXPIRED -->
            <ng-container
              *ngIf="
                !data.mySubscriptions.trial_ends_at ||
                (data.mySubscriptions.trial_ends_at &&
                  mySubscriptions.is_trial_expired)
              "
            >
              <!-- CHANGE FLAT FEE MONTHLY TO ANNUALLY -->
              <!-- prettier-ignore -->
              <ng-container *ngIf="changeTo === 'Month->Year' && priceYear">
                <li class="text-interface-body-2-regular text-default-neutral-1">
                  ${{ priceYear.amount | number: '1.2-2' }}
                </li>
                <li class="text-interface-body-2-regular text-default-neutral-3">
                  /{{ priceYear.interval }}
                </li>
              </ng-container>
              <!-- CHANGE FLAT FEE MONTHLY TO PAY IF YOU WIN -->
              <!-- prettier-ignore -->
              <ng-container *ngIf="changeTo === 'Month->PayIfYouWin' && pricePayIfYouWinMonth">
                <li class="text-interface-body-2-regular text-default-neutral-1">
                  ${{ nextSubscriptionPaymentPrice | number: '1.2-2'}}
                </li>
                <li class="text-interface-body-2-regular text-default-neutral-3">
                  Then ${{ pricePayIfYouWinMonth.amount * daysInMonth | number: '1.2-2' }} /Monthly
                </li>
              </ng-container>
              <!-- CHANGE PAY IF YOU WIN TO FLAT FEE MONTHLY -->
              <!-- prettier-ignore -->
              <ng-container *ngIf="changeTo === 'PayIfYouWin->Month' && priceMonth">
                <li class="text-interface-body-2-regular text-default-neutral-1">
                  ${{ priceMonth.amount | number: '1.2-2' }}
                </li>
                <li class="text-interface-body-2-regular text-default-neutral-3">
                  /{{ priceMonth.interval }}
                </li>
              </ng-container>
              <!-- CHANGE PAY IF YOU WIN TO FLAT FEE ANNUALLY -->
              <!-- prettier-ignore -->
              <ng-container *ngIf="changeTo === 'PayIfYouWin->Year' && priceYear">
                <li class="text-interface-body-2-regular text-default-neutral-1">
                  ${{ nextSubscriptionPaymentPrice | number: '1.2-2'}}
                </li>
                <li class="text-interface-body-2-regular text-default-neutral-3">
                  Then ${{ priceYear.amount | number: '1.2-2' }} /{{ priceYear.interval }}
                </li>
              </ng-container>
              <!-- CHANGE FLAT FEE ANNUALLY TO FLAT FEE MONTHLY -->
              <!-- prettier-ignore -->
              <ng-container *ngIf="changeTo === 'Year->Month' && priceMonth">
                <li class="text-interface-body-2-regular text-default-neutral-1">
                  ${{ priceMonth.amount | number: '1.2-2' }}
                </li>
                <li class="text-interface-body-2-regular text-default-neutral-3">
                  /{{ priceMonth.interval }}
                </li>
              </ng-container>
              <!-- CHANGE FLAT FEE ANNUALLY TO PAY IF YOU WIN -->
              <!-- prettier-ignore -->
              <ng-container *ngIf="changeTo === 'Year->PayIfYouWin' && pricePayIfYouWinMonth">
                <li class="text-interface-body-2-regular text-default-neutral-1">
                  ${{ pricePayIfYouWinMonth.amount * daysInMonth | number: '1.2-2' }}
                </li>
                <li class="text-interface-body-2-regular text-default-neutral-3">
                  /{{ pricePayIfYouWinMonth.interval }}
                </li>
              </ng-container>
            </ng-container>
            <!-- TRIAL NOT EXPIRED -->
            <ng-container
              *ngIf="
                data.mySubscriptions.trial_ends_at &&
                !mySubscriptions.is_trial_expired
              "
            >
              <li class="text-interface-body-2-regular text-default-neutral-1">
                {{ leftDaysOfTrial }} Days Free Trial
              </li>
              <!-- CHANGE FLAT FEE MONTHLY TO ANNUALLY -->
              <!-- prettier-ignore -->
              <ng-container *ngIf="changeTo === 'Month->Year' && priceYear">
                <li class="text-interface-body-2-regular text-default-neutral-3">
                  Then ${{ priceYear.amount | number: '1.2-2' }} /{{ priceYear.interval }}
                </li>
              </ng-container>
              <!-- CHANGE FLAT FEE MONTHLY TO PAY IF YOU WIN -->
              <!-- prettier-ignore -->
              <ng-container *ngIf="changeTo === 'Month->PayIfYouWin' && pricePayIfYouWinMonth">
                <li class="text-interface-body-2-regular text-default-neutral-3">
                  Then ${{ pricePayIfYouWinMonth.amount * daysInMonth | number: '1.2-2' }} /{{ pricePayIfYouWinMonth.interval }}
                </li>
              </ng-container>
              <!-- CHANGE PAY IF YOU WIN TO FLAT FEE MONTHLY -->
              <!-- prettier-ignore -->
              <ng-container *ngIf="changeTo === 'PayIfYouWin->Month' && priceMonth">
                <li class="text-interface-body-2-regular text-default-neutral-3">
                  Then ${{ priceMonth.amount | number: '1.2-2' }} /{{ priceMonth.interval }}
                </li>
              </ng-container>
              <!-- CHANGE PAY IF YOU WIN TO FLAT FEE ANNUALLY -->
              <!-- prettier-ignore -->
              <ng-container *ngIf="changeTo === 'PayIfYouWin->Year' && priceYear">
                <li class="text-interface-body-2-regular text-default-neutral-3">
                  Then ${{ priceYear.amount | number: '1.2-2' }} /{{ priceYear.interval }}
                </li>
              </ng-container>
              <!-- CHANGE FLAT FEE ANNUALLY TO FLAT FEE MONTHLY -->
              <!-- prettier-ignore -->
              <ng-container *ngIf="changeTo === 'Year->Month' && priceMonth">
                <li class="text-interface-body-2-regular text-default-neutral-3">
                  Then ${{ priceMonth.amount | number: '1.2-2' }} /{{ priceMonth.interval }}
                </li>
              </ng-container>
              <!-- CHANGE FLAT FEE ANNUALLY TO PAY IF YOU WIN -->
              <!-- prettier-ignore -->
              <ng-container *ngIf="changeTo === 'Year->PayIfYouWin' && pricePayIfYouWinMonth">
                <li class="text-interface-body-2-regular text-default-neutral-3">
                  Then ${{ pricePayIfYouWinMonth.amount * daysInMonth | number: '1.2-2' }} /{{ pricePayIfYouWinMonth.interval }}
                </li>
              </ng-container>
            </ng-container>
          </ul>
          <!-- NEXT PAYMENT -->
          <ul class="flex items-center !gap-1">
            <li class="text-interface-body-2-semibold text-default-neutral-1">
              Next Payment:
            </li>
            <li class="text-interface-body-2-regular">
              <ng-container *ngIf="changeTo === 'Month->Year'">
                {{ nextSubscriptionPaymentDate | date: 'MMM d, y' }}
              </ng-container>
              <!-- prettier-ignore -->
              <ng-container
                *ngIf="changeTo === 'PayIfYouWin->Month'"
              >
                {{ nextSubscriptionPaymentDate | date: 'MMM d, y' }}
              </ng-container>
              <!-- Для pay if you win - всегда 6 числа -->
              <!-- CHANGE FLAT FEE MONTHLY TO PAY IF YOU WIN -->
              <!-- CHANGE FLAT FEE ANNUALLY TO PAY IF YOU WIN -->
              <ng-container
                *ngIf="
                  changeTo === 'Year->PayIfYouWin' ||
                  changeTo === 'Month->PayIfYouWin'
                "
              >
                On {{ nowDay | date: 'MMMM' }} 6 {{ nowDay | date: ', y' }}
              </ng-container>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div class="flex !gap-2">
      <button
        class="kw-btn kw-btn-lg kw-btn-active kw-btn-light"
        type="button"
        (click)="close()"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="currentColor"
        >
          <path
            d="M15.7071 4.29289C16.0976 4.68342 16.0976 5.31658 15.7071 5.70711L9.41421 12L15.7071 18.2929C16.0976 18.6834 16.0976 19.3166 15.7071 19.7071C15.3166 20.0976 14.6834 20.0976 14.2929 19.7071L7.29289 12.7071C6.90237 12.3166 6.90237 11.6834 7.29289 11.2929L14.2929 4.29289C14.6834 3.90237 15.3166 3.90237 15.7071 4.29289Z"
          />
        </svg>
        <span class="block"> Go Back </span>
      </button>
      <button
        class="kw-btn kw-btn-lg kw-btn-active kw-btn-primary w-full"
        type="button"
        [disabled]="isDisabledChangeBtn"
        (click)="onChangePlan()"
      >
        <span class="block"> Yes, Continue </span>
      </button>
    </div>
  </div>
</div>
