// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

import { AppEnvironment } from './interfaces/environment';

export const environment: AppEnvironment = {
  production: false,
  apiWidgetsUrl: 'https://kw-vs.ru/widgets/api',
  apiMainUrl: 'https://kw-vs.ru/api',
  apiAdminUrl: 'https://kw-vs.ru/admin/api/v1',
  facebookAppId: '1223629191596356',
  googleClientId:
    '401120992888-p4kquf5vdupavjv3s5a3m2he6ko5if4g.apps.googleusercontent.com',
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
