import {
  AfterViewInit,
  Component,
  ElementRef,
  Input,
  ViewChild,
} from '@angular/core';

@Component({
  selector: 'app-premium-new-details, [appPremiumNewDetails]',
  templateUrl: './premium-new-details.component.html',
})
export class PremiumNewDetailsComponent implements AfterViewInit {
  @ViewChild('detailContent') detailContent: ElementRef | undefined;

  @Input()
  set appTitle(title: string) {
    this.title = title;
  }

  title: string;
  isShow: boolean = true;
  heightContent: number = 0;

  constructor() {}

  ngAfterViewInit(): void {
    this.heightContent = this.detailContent?.nativeElement.offsetHeight;

    this.isShow = false;
  }
}
