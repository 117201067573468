import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-loader-new-2',
  templateUrl: 'loader-new-2.component.html',
})
export class LoaderNew2Component {
  @Input()
  set appHeight(height: string) {
    this.height = height;
  }

  height: string = '112px';

  constructor() {}
}
